import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { StorageService } from './storage.service';
declare var Fingerprint: any
var fp1 = new Fingerprint();

@Injectable({
  providedIn: 'root'
})

export class ApiService {

  public user: any;
  public httpOptions: any;
  public fileupload: any;
  public httpotpions: { headers: HttpHeaders }
  public deviceIdentifier = fp1.get();

  //Live Api URL
  // public domain = "https://api.junaidkaliwala.com/";

  //Test Api URL
  public domain = "https://test-api.junaidkaliwala.com/";

  //Local Api URL
  // public domain = "http://localhost:5018/";

  constructor(private http: HttpClient,
    public router: Router,
    public toastr: ToastrService,
    public spinner: NgxSpinnerService,
    public storage: StorageService) { }

  getHeader(type) {
    return this.storage.getItem('userdetail').then(res => {
      if (res) {
        if (type == 'all') {
          return this.httpotpions = {
            headers: new HttpHeaders({
              "Content-Type": "application/json",
              "Authorization": "Bearer " + res['token'],
            })
          };
        } else if (type == 'token') {
          return this.httpotpions = {
            headers: new HttpHeaders({
              "Authorization": "Bearer " + res['token'],
            })
          };
        } else if (type == 'null') {
          return this.httpotpions = {
            headers: new HttpHeaders({
              "Content-Type": "application/json"
            })
          };
        }
      }
    });
  }

  getRequest(api, data) {
    return new Promise((resolve, reject) => {
      this.getHeader('token').then((dbres) => {
        if (dbres) {
          this.http.get<any>(this.domain + api + data, this.httpotpions).subscribe(res => {
            //if condition data validation
            resolve(res);
          }, error => {
            reject(error);
          })
        }
        else {
          this.http.get<any>(this.domain + api + data).subscribe(res => {
            //if condition data validation
            resolve(res);
          }, error => {
            reject(error);
          })
        }
      });
    });
  }

  postRequest(api, data, header = null) {
    return new Promise((resolve, reject) => {
      this.getHeader(header).then((dbres) => {
        if (dbres) {
          this.http.post<any>(this.domain + api, data, this.httpotpions).subscribe(res => {
            //if condition data validation
            resolve(res);
          }, error => {
            reject(error);
          })
        } else {
          this.http.post<any>(this.domain + api, data).subscribe(res => {
            //if condition data validation
            resolve(res);
          }, error => {
            reject(error);
          })
        }
      });
    });
  }

  putRequest(api, data) {
    return new Promise((resolve, reject) => {
      this.getHeader('all').then((dbres) => {
        if (dbres) {
          this.http.put<any>(this.domain + api, data, this.httpotpions).subscribe(res => {
            //if condition data validation
            resolve(res);
          }, error => {
            reject(error);
          })
        } else {
          this.http.put<any>(this.domain + api, data).subscribe(res => {
            //if condition data validation
            resolve(res);
          }, error => {
            reject(error);
          })
        }
      });
    });
  }

  deleteRequest(api, data, header = null) {
    return new Promise((resolve, reject) => {
      this.getHeader(header).then((dbres) => {
        if (dbres) {
          this.http.delete<any>(this.domain + api, data).subscribe(res => {
            //if condition data validation
            resolve(res);
          }, error => {
            reject(error);
          })
        } else {
          this.http.delete<any>(this.domain + api, data).subscribe(res => {
            //if condition data validation
            resolve(res);
          }, error => {
            reject(error);
          })
        }
      });
    });
  }

}

