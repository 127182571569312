import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ConstantsService } from '../service/constant-service'
import { ApiService } from '../service/api.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
declare var Swiper: any;
declare var $:any;
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  sliderFetch: any;
  categoryFetch: any;
  caregoryshow: any;
  addFrom: any;
  firstname: any;
  lastname: any;
  emailid: any;
  msg: any;

  constructor(public router: Router,
    private apiservices: ApiService,
    private spinner: NgxSpinnerService,
    public constantsService: ConstantsService,
    public FormBuilder: FormBuilder,
  ) {
    this.addFrom = this.FormBuilder.group({  //"^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,}$"
      FName: new FormControl('', [Validators.required]),
      LName: new FormControl('', [Validators.required]),
      Email: new FormControl('', Validators.compose([Validators.email, Validators.required])),
      Message: new FormControl('', [Validators.required]),
    })
  }

  ngOnInit(): void {
    // this.spinner.show();
    this.Slider();
    this.Category();

    var swiper = new Swiper('.swiper-container1', {
      spaceBetween: 30,
      centeredSlides: true,
      loop: true,
      autoplay: {
        delay: 2500,
        disableOnInteraction: false,
      },
      pagination: {
        el: '.swiper-pagination1',
        clickable: true,
      },
      navigation: {
        nextEl: '.swiper-button-next1',
        prevEl: '.swiper-button-prev1',
      },
    });


  }

  routing(data){
    if(data == 'Training Programs'){
     this.router.navigate(['/shop/lifestyle-general-fitness'])        
    }
    else if(data == 'E-Books'){
     this.router.navigate(['/shop/ebooks'])
    }
    else if(data == 'Merchandise'){
     this.router.navigate(['/shop/merchandise'])
    }
    
    
   }

  // ScrollToCategory(id) {
  //   this.router.navigate(['/shop']).then(e => {
  //     if (e) {
  //       setTimeout(function () {
  //         this.services = document.getElementById(id);
  //         //console.log(this.services.offsetTop);
  //         window.scrollTo(0, this.services.offsetTop);
  //       }, 600);
  //     } else {
  //       setTimeout(function () {
  //         this.services = document.getElementById(id);
  //         //console.log(this.services.offsetTop);
  //         window.scrollTo(0, this.services.offsetTop);
  //       }, 600);
  //     }
  //     //console.log("how");
  //   });
  // }

  Slider() {
    var data = ''
    this.apiservices.getRequest(this.constantsService.SLIDER, data).then(res => {
      //console.log(res);
      this.sliderFetch = res['data'];
    })
  }

  Category() {
    setTimeout(() => {
      this.categoryFetch = [];
      var data = ''
      this.apiservices.getRequest(this.constantsService.CATEGORY, data).then(res => {

        //console.log(res);
        for (let j of res['data']) {
          if (j.categoryPhoto != null) {
            this.categoryFetch.push(j);
            //console.log(this.categoryFetch);
            // this.spinner.hide();
          }
        }
      })
    }, 1000);

  }
}