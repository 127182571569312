<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#C59E3E" type="square-loader">
    <p style="color: white"> Loading...</p>
</ngx-spinner>
<section class="engine">
    <a href="https://mobirise.info/u">bootstrap html templates</a>
</section>
<section class="mbr-section form1 cid-rYR4j0zXqP">
    <div class="container">
        <div class="row justify-content-center">
            <div class="title col-12 col-lg-8">
                <h2 class="mbr-section-title align-center pb-3 mbr-fonts-style display-2">
                    CHECKOUT
                </h2>
            </div>
        </div>
    </div>
    <div class="container">
        <form [formGroup]="userInfoForm" autocomplete="off">
            <div class="row justify-content-center">
                <div class="col-12 col-md-8" *ngIf="!guestUserInfo" data-form-type="formoid">
                    <!--Formbuilder Form-->
                    <h3 class="mbr-section-subtitle align-left mbr-light pb-3 mbr-fonts-style display-5">
                        BILLING DETAILS</h3>
                    <div class="dragArea row">
                        <div class="col-lg-6  form-group" data-for="name">
                            <label for="name-form1-9" class="form-control-label mbr-fonts-style display-7">FIRST
                                NAME*</label>
                            <input type="text" name="fname" formControlName="firstName" data-form-field="FName"
                                required="required" class="form-control display-7">
                            <div *ngIf="userInfoForm.get('firstName').invalid && (userInfoForm.get('firstName').dirty || userInfoForm.get('firstName').touched)"
                                class="alert">
                                <div *ngIf="userInfoForm.get('firstName').errors.required">
                                    First Name is required.
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6  form-group" data-for="name">
                            <label for="name-form1-9" class="form-control-label mbr-fonts-style display-7">LAST
                                NAME*</label>
                            <input type="text" name="lname" formControlName="lastName" data-form-field="LName"
                                required="required" class="form-control display-7">
                            <div *ngIf="userInfoForm.get('lastName').invalid && (userInfoForm.get('lastName').dirty || userInfoForm.get('lastName').touched)"
                                class="alert">
                                <div *ngIf="userInfoForm.get('lastName').errors.required">
                                    Last Name is required.
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6  form-group" data-for="email">
                            <label for="email-form1-9"
                                class="form-control-label mbr-fonts-style display-7">EMAIL*</label>
                            <input type="email" name="email" formControlName="email" data-form-field="Email"
                                required="required" class="form-control display-7">
                            <div *ngIf="userInfoForm.get('email').invalid && (userInfoForm.get('email').dirty || userInfoForm.get('email').touched)"
                                class="alert">
                                <div *ngIf="userInfoForm.get('email').errors.required">
                                    Email is required.
                                </div>
                                <div *ngIf="userInfoForm.get('email').errors.pattern">
                                    Please enter a valid email address
                                </div>
                            </div>
                        </div>

                        <div data-for="phone" class="col-lg-6  form-group">
                            <label for="phone-form1-9"
                                class="form-control-label mbr-fonts-style display-7">PHONE</label>
                            <input type="tel" name="phone" formControlName="phone" data-form-field="Phone"
                                class="form-control display-7">
                            <div *ngIf="userInfoForm.get('phone').invalid && (userInfoForm.get('phone').dirty || userInfoForm.get('phone').touched)"
                                class="alert">
                                <div
                                    *ngIf="userInfoForm.get('phone').errors.minlength || userInfoForm.get('phone').errors.maxlength || userInfoForm.get('phone').errors.pattern">
                                    Please enter valid phone number </div>
                            </div>
                        </div>

                        <div class="col-lg-6  form-group" data-for="email">
                            <label for="email-form1-9" class="form-control-label mbr-fonts-style display-7">APARTMENT,
                                SUITE, UNIT, ETC*</label>
                            <input type="text" name="email" data-form-field="Email" required="required"
                                class="form-control display-7" formControlName="houseNo">
                            <div *ngIf="userInfoForm.get('houseNo').invalid && (userInfoForm.get('houseNo').dirty || userInfoForm.get('houseNo').touched)"
                                class="alert">
                                <div *ngIf="userInfoForm.get('houseNo').errors.required">
                                    APARTMENT, SUITE, UNIT, ETC is required.
                                </div>
                            </div>
                        </div>
                        <div data-for="phone" class="col-lg-6  form-group">
                            <label for="phone-form1-9" class="form-control-label mbr-fonts-style display-7">STREET
                                ADDRESS* </label>
                            <input type="text" name="phone" data-form-field="Phone" class="form-control display-7"
                                formControlName="street">
                            <div *ngIf="userInfoForm.get('street').invalid && (userInfoForm.get('street').dirty || userInfoForm.get('street').touched)"
                                class="alert">
                                <div *ngIf="userInfoForm.get('street').errors.required">
                                    STREET ADDRESS is required.
                                </div>
                            </div>
                        </div>
                        <div data-for="phone" class="col-lg-6  form-group">
                            <label for="phone-form1-9" class="form-control-label mbr-fonts-style display-7">AREA*
                            </label>
                            <input type="text" name="phone" data-form-field="Phone" class="form-control display-7"
                                formControlName="area">
                            <div *ngIf="userInfoForm.get('area').invalid && (userInfoForm.get('area').dirty || userInfoForm.get('area').touched)"
                                class="alert">
                                <div *ngIf="userInfoForm.get('area').errors.required">
                                    AREA is required.
                                </div>
                            </div>
                        </div>
                        <div data-for="phone" class="col-lg-6  form-group">
                            <label for="phone-form1-9" class="form-control-label mbr-fonts-style display-7">TOWN / CITY*
                            </label>
                            <input type="text" name="phone" data-form-field="Phone" class="form-control display-7"
                                formControlName="town">
                            <div *ngIf="userInfoForm.get('town').invalid && (userInfoForm.get('town').dirty || userInfoForm.get('town').touched)"
                                class="alert">
                                <div *ngIf="userInfoForm.get('town').errors.required">
                                    TOWN / CITY is required.
                                </div>
                            </div>
                        </div>
                        <div data-for="phone" class="col-lg-6  form-group">
                            <label for="phone-form1-9"
                                class="form-control-label mbr-fonts-style display-7">STATE*</label>
                            <input type="text" name="phone" data-form-field="Phone" class="form-control display-7"
                                formControlName="state">
                            <div *ngIf="userInfoForm.get('state').invalid && (userInfoForm.get('state').dirty || userInfoForm.get('state').touched)"
                                class="alert">
                                <div *ngIf="userInfoForm.get('state').errors.required">
                                    State is required.
                                </div>
                            </div>
                        </div>
                        <div data-for="phone" class="col-lg-6  form-group">
                            <label for="phone-form1-9" class="form-control-label mbr-fonts-style display-7">POSTCODE /
                                ZIP*
                            </label>
                            <input type="text" name="phone" data-form-field="Phone" class="form-control display-7"
                                formControlName="pinCode">
                            <div *ngIf="userInfoForm.get('pinCode').invalid && (userInfoForm.get('pinCode').dirty || userInfoForm.get('pinCode').touched)"
                                class="alert">
                                <div *ngIf="userInfoForm.get('pinCode').errors.required">
                                    POSTCODE / ZIP is required.
                                </div>
                                <div *ngIf="userInfoForm.get('pinCode').errors.maxlength">
                                    PLEASE ENTER VALID POSTCODE / ZIP.
                                </div>
                            </div>
                        </div>
                        <div data-for="message" class="col-lg-12  form-group">
                            <label for="phone-form1-9" class="form-control-label mbr-fonts-style display-7">MESSAGE
                                (Optional)
                            </label>
                            <textarea name="phone" data-form-field="Phone" class="form-control display-7"
                                formControlName="userMessage" rows="2"></textarea>
                        </div>
                    </div>
                    <!--Formbuilder Form IF GUEST DETAILS are SAVED in INDEXXED-->
                </div>
                <div class="col-12 col-md-8" *ngIf="guestUserInfo">
                    <div class="row">
                        <div class="col-12">
                            <h3 class="mbr-section-subtitle align-left mbr-light pb-3 mbr-fonts-style display-5">
                                BILLING DETAILS <i class="mdi mdi-pencil pointer" (click)="resetGuest()"
                                    data-toggle="tooltip" data-placement="top" title="RESET DETAILS"></i>
                            </h3>
                        </div>
                    </div>
                    <div class="dragArea row">
                        <div class="col-lg-12  form-group" data-for="name">
                            <label for="name-form1-9" class="form-control-label mbr-fonts-style display-7">FULL
                                NAME*</label>
                            <input type="text" class="form-control display-7" [value]="guestUserInfo.fullName" disabled>
                        </div>

                        <div class="col-lg-6  form-group" data-for="email">
                            <label for="email-form1-9"
                                class="form-control-label mbr-fonts-style display-7">EMAIL*</label>
                            <input type="email" class="form-control display-7" [value]="guestUserInfo.email" disabled>
                        </div>
                        <div data-for="phone" class="col-lg-6  form-group">
                            <label for="phone-form1-9"
                                class="form-control-label mbr-fonts-style display-7">PHONE</label>
                            <input type="tel" class="form-control display-7" [value]="guestUserInfo.phoneNumber" disabled>
                        </div>

                        <div class="col-lg-6  form-group" data-for="email">
                            <label for="email-form1-9" class="form-control-label mbr-fonts-style display-7">APARTMENT,
                                SUITE, UNIT, ETC*</label>
                            <input type="text" class="form-control display-7" [value]="guestUserInfo.houseNumber"
                                disabled>
                        </div>
                        <div data-for="phone" class="col-lg-6  form-group">
                            <label for="phone-form1-9" class="form-control-label mbr-fonts-style display-7">STREET
                                ADDRESS* </label>
                            <input type="text" class="form-control display-7" [value]="guestUserInfo.streetAddress"
                                disabled>
                        </div>
                        <div data-for="phone" class="col-lg-6  form-group">
                            <label for="phone-form1-9" class="form-control-label mbr-fonts-style display-7">AREA*
                            </label>
                            <input type="text" class="form-control display-7" [value]="guestUserInfo.area" disabled>
                        </div>
                        <div data-for="phone" class="col-lg-6  form-group">
                            <label for="phone-form1-9" class="form-control-label mbr-fonts-style display-7">TOWN / CITY*
                            </label>
                            <input type="text" class="form-control display-7" [value]="guestUserInfo.city" disabled>
                        </div>
                        <div data-for="phone" class="col-lg-6  form-group">
                            <label for="phone-form1-9" class="form-control-label mbr-fonts-style display-7">STATE*
                            </label>
                            <input type="text" class="form-control display-7" [value]="guestUserInfo.state" disabled>
                        </div>
                        <div data-for="phone" class="col-lg-6  form-group">
                            <label for="phone-form1-9" class="form-control-label mbr-fonts-style display-7">POSTCODE /
                                ZIP*
                            </label>
                            <input type="text" class="form-control display-7" [value]="guestUserInfo.postCode" disabled>
                        </div>
                        <div data-for="message" class="col-lg-12  form-group">
                            <label for="phone-form1-9" class="form-control-label mbr-fonts-style display-7">MESSAGE
                                (Optional)
                            </label>
                            <textarea name="phone" data-form-field="Phone" class="form-control display-7"
                                rows="2"></textarea>
                        </div>
                    </div>
                </div>

                <div class="col-12 col-md-4">
                    <h3 class="mbr-section-subtitle align-left mbr-light pb-3 mbr-fonts-style display-5">
                        ORDER SUMMARY</h3>
                    <div class="row">
                        <div class="col-12 py-1" *ngFor="let i of cart.cart_Data">
                            <div class="row">
                                <div class="col-4 col-sm-3 col-md-12 col-lg-4">
                                    <img src={{i.shop.productPhoto}} class="img-fluid">
                                </div>
                                <div class="col-8 col-sm-9 col-md-12 col-lg-8">
                                    <div class="row">
                                        <div class="col-12">
                                            <h6 class="align-left mbr-fonts-style fontsm text-left mb-1">
                                                {{i.shop.productName}} <span *ngIf="i.size">({{i.size}})</span>
                                            </h6>
                                            <p class="fontcat mb-0" *ngIf="!i.discount">
                                                {{i.shop.price*i.quantity|currency:'INR':'symbol':'3.0-0'}}</p>
                                            <p *ngIf="!i.discount" class="fontcat pt-0">Quantity: {{i.quantity}}</p>
                                            <div *ngIf="i.codeType=='PERCENTAGE'">
                                                <p class="fontcat mb-0"><del
                                                        style="color: red;">{{i.shop.price*i.quantity|currency:'INR':'symbol':'3.0-0'}}</del>
                                                    {{(i.shop.price*i.quantity)-(((i.shop.price*i.quantity)*i.discount)/100)|currency:'INR':'symbol':'3.0-0'}}
                                                </p>
                                                <p class="fontcat pt-0">Quantity: {{i.quantity}}</p>
                                            </div>
                                            <div *ngIf="i.codeType=='FLAT'">
                                                <p class="fontcat mb-0"><del
                                                        style="color: red;">{{i.shop.price*i.quantity|currency:'INR':'symbol':'3.0-0'}}</del>
                                                    {{(i.shop.price*i.quantity)-i.discount|currency:'INR':'symbol':'3.0-0'}}
                                                </p>
                                                <p class="fontcat pt-0">Quantity: {{i.quantity}}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr style="border:1px solid #f9f9f9;width:91%;">
                        <div class="col-12 d-flex">
                            <p class="m-0">Subtotal:</p>
                            <p class="ml-auto m-0">
                                {{cart.getProductPrice|currency:'INR':'symbol':'3.0-0'}}
                            </p>
                        </div>
                        <span *ngIf="cart.gettotalsaleamontforpay" class="w-100">
                            <hr style="border:1px solid #f9f9f9;width:91%;">
                            <div class="col-12 d-flex">
                                <p class="m-0">Sale Price:</p>
                                <p class="ml-auto m-0">{{cart.gettotalsaleamontforpay|currency:'INR':'symbol':'3.0-0'}}
                                </p>
                            </div>
                        </span>
                        <span *ngIf="cart.gettotaldiscount" class="w-100">
                            <hr style="border:1px solid #f9f9f9;width:91%;">
                            <div class="col-12 d-flex">
                                <p class="m-0">Discount ({{couponcode}}):</p>
                                <p class="ml-auto m-0">{{cart.gettotaldiscount|currency:'INR':'symbol':'3.0-0'}}</p>
                            </div>
                        </span>
                        <hr style="border:1px solid #f9f9f9;width:91%;">
                        <div class="col-12 d-flex" *ngIf="cart.gettotalamontforpay">
                            <p class="m-0"><strong>Total Price:</strong></p>
                            <p class="ml-auto m-0">
                                <strong>{{cart.gettotalamontforpay|currency:'INR':'symbol':'3.0-0'}}</strong>
                            </p>
                        </div>
                        <hr style="border:1px solid #f9f9f9;width:91%;">
                        <div class="col-12">
                            <div class="row align-items-center">
                                <div class="col-8 col-sm-8 pr-0">
                                    <input type="text" name="fname" class="form-control1 display-7"
                                        placeholder="APPLY COUPON CODE" value="APPLY COUPON CODE"
                                        [(ngModel)]="couponcode" [ngModelOptions]="{standalone: true}" disabled>
                                </div>
                                <div class="col-4 col-sm-4 pl-0">
                                    <button type="submit" class="btn btn-primary btn-form m-0 w-100 px-1 h-100"
                                        (click)="removecoupon()" data-toggle="modal"
                                        data-target="#couponModal">SELECT</button>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 mt-4 input-group-btn align-center">
                            <button type="submit" class="btn btn-primary btn-form display-4"
                                (click)="orderNpayment()">PLACE ORDER</button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</section>

<div class="modal fade" id="couponModal" tabindex="-1" role="dialog" aria-labelledby="couponModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" *ngIf="!getcouponcode" id="couponModalLabel">NO COUPON CODE AVAILABLE
                </h5>
                <h5 class="modal-title" *ngIf="getcouponcode" id="couponModalLabel">APPLY COUPON CODE</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body" style="max-height:70vh; overflow-y: scroll;">
                <div class="row align-items-center">
                    <div class="col-12">
                        <div class="py-2 px-2" *ngFor="let u of uniquecode">
                            <div class="m-4">
                                <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios1"
                                    value="{{u}}" [ngModelOptions]="{standalone: true}" [(ngModel)]="couponcodes"
                                    (click)="selectedCoupon(u)">
                                <label class="form-check-label" for="exampleRadios1">
                                    {{u}}
                                </label>
                            </div>

                            <div *ngFor="let c of getcouponcode">
                                <div class="row my-1" *ngIf="c.coupan_code.name==u">
                                    <div class="col-4">
                                        <img [src]="c.shop.productPhoto" class="img-fluid">
                                    </div>
                                    <div class="col-8">
                                        <div class="row">
                                            <div class="col-12">
                                                <h6 class="align-left mbr-fonts-style fontsm text-left mb-1">
                                                    {{c.shop.productName}}
                                                </h6>
                                                <p class="fontcat mb-0">{{c.shop.category.categoryName}}</p>
                                            </div>
                                            <div class="col-12 d-flex" *ngIf="c.coupan_code.codeType=='FLAT'">
                                                <p class="m-0">Discount:</p>
                                                <p class="ml-auto m-0">
                                                    {{(c.coupan_code.discount)|currency:'INR':'symbol':'3.0-0'}}
                                                </p>
                                            </div>
                                            <div class="col-12 d-flex" *ngIf="c.coupan_code.codeType=='FLAT'">
                                                <p class="m-0"><strong>Total Price:</strong></p>
                                                <p class="ml-auto m-0">
                                                    <strong>{{((c.shop.price)-c.coupan_code.discount)|currency:'INR':'symbol':'3.0-0'}}</strong>
                                                </p>
                                            </div>
                                            <div class="col-12 d-flex" *ngIf="c.coupan_code.codeType=='PERCENTAGE'">
                                                <p class="m-0">Discount:</p>
                                                <p class="ml-auto m-0">
                                                    {{((c.shop.price)*c.coupan_code.discount)/100|currency:'INR':'symbol':'3.0-0'}}
                                                </p>
                                            </div>
                                            <div class="col-12 d-flex" *ngIf="c.coupan_code.codeType=='PERCENTAGE'">
                                                <p class="m-0"><strong>Total Price:</strong></p>
                                                <p class="ml-auto m-0">
                                                    <strong>{{(c.shop.price)-(((c.shop.price)*c.coupan_code.discount)/100)|currency:'INR':'symbol':'3.0-0'}}</strong>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer" *ngIf="couponcode">
                <button type="submit" class="btn btn-primary btn-form m-0 px-3 w-100" (click)="applyCoupon()"
                    data-dismiss="modal">APPLY</button>
            </div>
        </div>
    </div>
</div>