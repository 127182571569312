<!-- <app-header></app-header> -->
<!-- <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#C59E3E" type="square-loader">
    <p style="color: white"> Loading...</p>
    </ngx-spinner> -->
<section class="engine"><a href="https://mobirise.info">Mobirise</a></section>
<section class="engine"><a href="https://mobirise.info">Mobirise</a></section>
<section style="margin-top: 77px;">
    <div class="swiper-container swiper-container1">
        <div class="swiper-wrapper">
            <div class="swiper-slide"> <img class="d-block w-100" src="/assets/images/slidernew (1).jpg"
                    alt="First slide"></div>
            <div class="swiper-slide"><img class="d-block w-100" src="/assets/images/slidernew (2).jpg"
                    alt="Second slide"></div>
            <div class="swiper-slide"><img class="d-block w-100" src="/assets/images/slidernew (3).jpg"
                    alt="Third slide"></div>
            <div class="swiper-slide"><img class="d-block w-100" src="/assets/images/slidernew (4).jpg"
                    alt="Third slide"></div>
        </div>
        <!-- Add Pagination -->
        <div class="swiper-pagination swiper-pagination1"></div>
        <!-- Add Arrows -->
        <div class="swiper-button-next swiper-button-next1 d-none d-sm-flex"><i
                class="mdi mdi-arrow-right-circle-outline mdi-48px" style="color: #fff !important;"
                aria-hidden="true"></i></div>
        <div class="swiper-button-prev swiper-button-prev1 d-none d-sm-flex"><i
                class="mdi mdi-arrow-left-circle-outline mdi-48px" style="color: #fff !important;"
                aria-hidden="true"></i></div>
    </div>
</section>

<section class="services2 cid-rYRcc5qF3z" id="services2-e">
    <div class="container">
        <div class="col-md-12">
            <div class="media-container-row">
                <div class="mbr-figure" style="width: 50%;">
                    <img src="assets/images/aboutimg.jpg" alt="Junaid Kaliwala" title="Who is Junaid Kaliwala?">
                </div>
                <div class="align-left aside-content">
                    <h2 class="mbr-title pt-2 mbr-fonts-style display-2">Who is Junaid Kaliwala?</h2>
                    <div class="mbr-section-text">
                        <p class="mbr-text text1 pt-2 mbr-light mbr-fonts-style display-7">India's First Professional
                            (IFBB PRO) Men’s Physique Athlete by Avocation and Transformation specialist by Profession,
                            is a certified Fitness Expert. His knowledge, experience, grounded nature and the hunger to
                            learn makes him stand out in today’s competitive fitness world.<br><br>He is certified in
                            strength and conditioning and is also a certified kettle bell and functional
                            trainer.<br><br>His ambition is to bring awareness amongst people to adopt a healthy
                            lifestyle, help others achieve their fitness goals and create a distinct transformation of
                            lives. Being a peoples' person, he rules over the heart of many for his
                            humbleness.<br><br>He is the first IFBB PRO physique athlete and an award-winning personal
                            trainer and professional fitness model.<br></p>
                    </div>
                </div>
            </div>
        </div>
        <!-- Button trigger modal -->
        <!-- <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal6">
            Open Modal
        </button> -->

        <!-- Modal -->
        <!-- <div class="modal fade" id="exampleModal6" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">

                    <div class="modal-header">
                        <h4>Join our Link</h4>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>

                    <div class="modal-body">

                    </div>
                    <div class="row">
                        <div class="col half">
                            <div class="input-group mb-3 px-3">
                                <input type="text" class="form-control" placeholder="Name" aria-label="Username"
                                    aria-describedby="basic-addon1">
                            </div>
                        </div>
                        <div class="col half">
                            <div class="input-group mb-3 px-3">
                                <input type="number" class="form-control" placeholder="Mobile" aria-label="Username"
                                    aria-describedby="basic-addon1">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col half">
                            <div class="input-group mb-3 px-3">
                                <input type="email" class="form-control" placeholder="Email" aria-label="Username"
                                    aria-describedby="basic-addon1">
                            </div>
                        </div>
                        <div class="col half">
                            <div class="input-group mb-3 px-3">
                                <input type="city" class="form-control" placeholder="City" aria-label="Username"
                                    aria-describedby="basic-addon1">
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer justify-content-center">
                        <button type="button" class="btn btn-primary">Submit</button>
                    </div>
                </div>
            </div>
        </div> -->
    </div>
</section>

<section class="services1 cid-rZms5pEXtW" id="services1-1d">
    <div class="container">
        <div class="row justify-content-center">
            <div class="title pb-5 col-12">
            </div>
            <div class="card col-12 col-md-6 p-3 col-lg-4" *ngFor="let j of categoryFetch">
                <div class="services1  card-wrapper" (click)="routing(j.categoryName)">
                    <div class="card-img">
                        <a><img src="{{j.categoryPhoto}}" alt="Online Training" title="Online Training"></a>
                    </div>
                    <div class="card-box">
                        <h4 class="card-title mbr-fonts-style slide-content">
                            {{j.categoryName}}</h4>
                        <!-- (click)="ScrollToCategory(j.id)" -->
                        <!-- #desktop -->
                        <div class="mbr-section-btn align-center d-none d-md-none d-lg-block"><a
                                class="btn btn-primary display-4">
                                Learn More</a></div>
                        <!-- #mobile -->
                        <div class="mbr-section-btn align-center d-block d-md-none d-lg-none"><a
                                class="btn1 btn-primary display-4">
                                Learn More</a></div>
                        <!-- #ipad  -->
                        <div class="mbr-section-btn align-center d-none d-md-block d-lg-none"><a
                                class="btn1 btn-primary display-4">
                                Learn More</a></div>


                    </div>
                </div>
            </div>
            <!-- <div class="card col-12 col-md-6 p-3 col-lg-4">
                <div class="card-wrapper">
                    <div class="card-img">
                        <a href="shop.html#services1-j"><img src="assets/images/mbr-4-696x464.jpg" alt="E-Books"
                                title="E-Books"></a>
                    </div>
                    <div class="card-box">
                        <h4 class="card-title mbr-fonts-style slide-content">
                            E-Books</h4>
                        <div class="mbr-section-btn align-center"><a (click)="ScrollToEBook()"
                                class="btn btn-primary display-4">
                                Learn More</a></div>
                    </div>
                </div>
            </div>
            <div class="card col-12 col-md-6 p-3 col-lg-4 last-child">
                <div class="card-wrapper">
                    <div class="card-img">
                        <a href="shop.html#services1-l"><img src="assets/images/mbr-5-696x464.jpg" alt="Merchandise"
                                title="Merchandise"></a>
                    </div>
                    <div class="card-box">
                        <h4 class="card-title mbr-fonts-style slide-content">Merchandise</h4>
                        <div class="mbr-section-btn align-center"><a (click)="ScrollToMerchandise()"
                                class="btn btn-primary display-4">
                                Learn More</a></div>
                    </div>
                </div>
            </div> -->
        </div>

    </div>
</section>

<div class="modal fade" id="addreview" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content boxs">
            <div class="modal-body p-5">
                <button type="button" class="close position-absolute" style="top: 5px; right: 10px;"
                    data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
                <h1 class="mb-3 head text-pink">Leave your Review</h1>
                <p class="mb-3"> Enter your details:</p>
                <form [formGroup]="addFrom">
                    <div class="row">
                        <div class="col-12 col-md-6">
                            <div class="form-group">
                                <input type="text" class="form-control br-0 btn-light border-pink px-4 py-3"
                                    [(ngModel)]="firstname" placeholder="First Name*" name="snsnsn"
                                    formControlName="FName" [(ngModel)]="firstname" name="dfgh">
                                <div *ngIf="addFrom.get('FName').invalid && (addFrom.get('FName').dirty || addFrom.get('FName').touched)"
                                    class="alert">
                                    <div *ngIf="addFrom.get('FName').errors.required">
                                        First Name is required.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-6">
                            <div class="form-group">
                                <input type="text" class="form-control br-0 btn-light border-pink px-4 py-3"
                                    [(ngModel)]="lastname" placeholder="Last Name*" formControlName="LName">
                                <div *ngIf="addFrom.get('LName').invalid && (addFrom.get('LName').dirty || addFrom.get('LName').touched)"
                                    class="alert">
                                    <div *ngIf="addFrom.get('LName').errors.required">
                                        Last Name is required.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="form-group">
                                <input type="email" class="form-control br-0 btn-light border-pink px-4 py-3"
                                    [(ngModel)]="emailid" placeholder="Email Address*" formControlName="Email"
                                    id="exampleInputPassword1">
                                <div *ngIf="addFrom.get('Email').invalid && (addFrom.get('Email').dirty || addFrom.get('Email').touched)"
                                    class="alert">
                                    <div *ngIf="addFrom.get('Email').errors.required">
                                        Email is required.
                                    </div>
                                    <div *ngIf="addFrom.get('Email').errors.email">
                                        Please include an '@' in the email address
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="form-group">
                                <textarea class="form-control br-0 btn-light border-pink px-4 py-3" rows="3"
                                    [(ngModel)]="msg" placeholder="Write your review*" name="message"
                                    formControlName="Message"></textarea>
                                <div *ngIf="addFrom.get('Message').invalid && (addFrom.get('Message').dirty || addFrom.get('Message').touched)"
                                    class="alert">
                                    <div *ngIf="addFrom.get('Message').errors.required">
                                        Review is required.
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-12 text-center text-lg-left">
                            <button type="button" [disabled]="!addFrom.valid" data-dismiss="modal"
                                class="btn btn-dark py-1 px-5 br-0 f-0" style="background-color: black;">SUBMIT YOUR
                                REVIEW</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

<input name="cookieData" type="hidden" data-cookie-customDialogSelector='null' data-cookie-colorText='#424a4d'
    data-cookie-colorBg='rgba(234, 239, 241, 0.99)' data-cookie-textButton='Agree' data-cookie-colorButton='#c59e3e'
    data-cookie-colorLink='#424a4d' data-cookie-underlineLink='true'
    data-cookie-text="We use cookies to give you the best experience. ">