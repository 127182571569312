import { Component, OnDestroy, OnInit } from '@angular/core';
import { ApiService } from '../service/api.service';
import { ConstantsService } from '../service/constant-service';
import { FormControl, Validators, FormBuilder, FormGroup } from '@angular/forms';
import { CartService } from '../service/cart.service';
import { StorageService } from '../service/storage.service';

declare var Razorpay: any;
declare var Fingerprint: any;
var fp1 = new Fingerprint();

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss']
})
export class CheckoutComponent implements OnInit, OnDestroy {


  public defaultUserId: any;
  public guestUserInfo: any;
  public couponcode: any;
  public getGuestId: any;
  public getcouponcode: any = [];
  public couponcodes: any;
  public uniquecode: any = [];

  //Form Group
  public userInfoForm: FormGroup;

  constructor(
    public cart: CartService,
    private apiService: ApiService,
    public constant: ConstantsService,
    public FormBuilder: FormBuilder) {
    this.setForm();
  }

  ngOnInit(): void {
    this.defaultUserId = fp1.get();
    this.cart.getCartData();
    this.cart.gettotaldiscount = null
    setTimeout(() => {
      this.removecoupon();
      this.guestDetails()
      this.guestId()
      this.getCoupon();
    }, 1000);
  }

  ngOnDestroy(): void {
    this.removecoupon();
    this.cart.gettotaldiscount = null;
  }

  setForm() {
    this.userInfoForm = this.FormBuilder.group({
      firstName: new FormControl('', [Validators.required]),
      lastName: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,}$")]),
      phone: new FormControl('', Validators.compose([Validators.minLength(8), Validators.maxLength(11), Validators.pattern(/^-?(0|[1-9]\d*)?$/)])),
      houseNo: new FormControl('', [Validators.required]),
      street: new FormControl('', [Validators.required]),
      area: new FormControl('', [Validators.required]),
      town: new FormControl('', [Validators.required]),
      state: new FormControl('', [Validators.required]),
      pinCode: new FormControl('', Validators.compose([Validators.maxLength(8), Validators.required])),
      userMessage: new FormControl('')
    })
  }

  async guestDetails() {
    if (await this.apiService.storage.getItem('guestdetail')) {
      this.guestUserInfo = await this.apiService.storage.getItem('guestdetail');
    }
  }

  async guestId() {
    if (await this.apiService.storage.getItem('guestid')) {
      this.getGuestId = await this.apiService.storage.getItem('guestid');
    }
  }

  async getCoupon() {
    this.cart.getCartData();
    this.cart.cart_Data.forEach(element => {
      var data = '';
      this.apiService.getRequest(this.constant.GETCOUPON + '?shopId=' + element.shop.id, data).then(res => {
        res['data'].forEach(resdata => {
          if (!this.uniquecode.includes(resdata.coupan_code.name)) {
            this.uniquecode.push(resdata.coupan_code.name)
          }
          this.getcouponcode.push(resdata)
        });
      })
    });
  }

  orderNpayment() {
    this.guestDetails();
    this.guestId()
    if (this.getGuestId) {
      this.razorpay();
    } else {
      this.guestEntry()
    }
  }

  async guestEntry() {
    if (this.userInfoForm.invalid) {
      this.apiService.toastr.error("Please fill in your billing details.");
      return;
    }

    let data = {
      "deviceIdentifier": this.defaultUserId,
      "fullName": this.userInfoForm.value.firstName + " " + this.userInfoForm.value.lastName,
      "email": this.userInfoForm.value.email,
      "phoneNumber": this.userInfoForm.value.phone,
      "postCode": this.userInfoForm.value.pinCode,
      "houseNumber": this.userInfoForm.value.houseNo,
      "streetAddress": this.userInfoForm.value.street,
      "area": this.userInfoForm.value.area,
      "city": this.userInfoForm.value.town,
      "state": this.userInfoForm.value.state,
      "country": "India"
    }
    try {
      const res = await this.apiService.postRequest(this.constant.GUEST, data);
      if (res && res['guestId']) {
        let guestId = res['guestId'];
        await this.apiService.storage.updateItem('guestid', guestId);
        await this.apiService.storage.updateItem('guestdetail', data);
        let fetch = await this.apiService.storage.getItem('guestdetail');
        this.guestUserInfo = fetch;
        this.razorpay();
      }
    } catch (error) {
      console.log(error);
    }
  }

  razorpay() {
    try {
      let address = this.userInfoForm.value.houseNo + ", " + this.userInfoForm.value.street + ", " + this.userInfoForm.value.area + ", " + this.userInfoForm.value.town + "-" + this.userInfoForm.value.pinCode + ", " + this.userInfoForm.value.state + ".";
      let options = {
        key: "rzp_test_k1FCO8KxMtIAoJ",
        // key: "rzp_live_tb7Z0XYCGjZmfZ",
        amount: this.cart.gettotalamontforpay * 100,
        currency: "INR",
        name: this.userInfoForm.value.firstName + " " + this.userInfoForm.value.lastName,
        email: this.userInfoForm.value.email,
        description: "Junaid Kaliwala",
        image: "/assets/images/logo.png",
        "payment_capture": 1,
        handler: (response: any) => {
          this.order(response.razorpay_payment_id)
        },
        prefill: {
          name: this.guestUserInfo.fullName, // pass customer name
          email: this.guestUserInfo.email, // customer email
          contact: this.guestUserInfo.phoneNumber //customer phone no.
        },
        notes: {
          address: address //customer address
        },
        theme: {
          color: "black" // screen color
        }
      };
      let rzp1 = new Razorpay(options);
      rzp1.open();
    } catch (error) {
      console.log(error);
    }
  }

  async order(id: any) {
    this.apiService.spinner.show();
    let data = {
      "razorpayID": id,
      "deviceIdentifier": this.defaultUserId,
      "guestId": await this.apiService.storage.getItem('guestid'),
      "message": this.userInfoForm.value.userMessage,
    }

    try {
      const res = await this.apiService.postRequest(this.constant.ORDER, data);
      if (res) {
        let orderResp = res;
        await this.apiService.storage.updateItem('orderdetail', orderResp);
        this.apiService.spinner.hide();
        window.location.href = '/thankyou';
      }
    } catch (error) {
      console.log(error);
      this.apiService.spinner.hide();
    }
  }

  addcoupon(id, code) {
    let data = { "shopId": id, "coupanName": code };
    this.apiService.putRequest(this.constant.COUPON, data).then(res => {
      if (res['message']) {
        this.couponcode = code;
        this.cart.getCartData();
      }
    })
  }

  async removecoupon() {
    await this.cart.getCartData();
    this.cart.cart_Data.forEach(element => {
      this.apiService.deleteRequest(this.constant.REMOVECOUPON + '?shopId=' + element.shopId + '&deviceIdentifier=' + this.defaultUserId, '')
    });
    this.couponcode = '';
    this.couponcodes = '';
  }

  async selectedCoupon(name) {
    await this.removecoupon();
    this.couponcode = name;
  }

  async applyCoupon() {
    this.cart.cart_Data.forEach(element => {
      this.addcoupon(element.shopId, this.couponcode)
    })
    this.apiService.toastr.success(this.couponcode + ' Coupon Code Applied')
  }

  async resetGuest() {
    await this.apiService.storage.deleteItem('guestdetail');
    await this.apiService.storage.deleteItem('guestid');
    await this.apiService.storage.deleteItem('orderid');
    location.reload();
  }

}

